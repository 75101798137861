body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: scroll;
}
  
table {
    border-collapse: collapse;
    width: 100%;
}

thead {
    position: sticky;
    top: 0px;
    background-color: white;
}

tr, th, td {
    border: 0px solid transparent;
    background-clip: padding-box;
}

tr > td + td {
    border-left-width: 10px;    
}

tr + tr > td {
    border-top-width: 10px;
}

td {
    background-color: rgb(242, 245, 247);
}

.genLabelB {
    font-weight: bold;
    font-size: 12px;
    border-radius: 5px;
    padding: 2px;
    margin-left: 4px;
    margin-right: 4px;
    color: white;
    background-color: mediumblue;
    display: inline-block;
    min-width: 20px;
    text-align: center;
}

.genLabelG {
    font-weight: bold;
    font-size: 12px;
    border-radius: 5px;
    padding: 2px;
    margin-left: 4px;
    margin-right: 4px;
    color: white;
    background-color: teal;
    display: inline-block;
    min-width: 20px;
    text-align: center;
}

.genLabelBL {
    font-weight: bold;
    font-size: 10px;
    border-radius: 5px;
    padding: 2px;
    margin-left: 2px;
    margin-right: 5px;
    color: white;
    background-color: black;
    display: inline-block;
    width: 50px;
    text-align: center;
}

.elemFiltro {
    font-size: 10px;
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 3px;
    background-color: darkslategrey;
    color: white;
}

.filtroArea {
    background-color: saddlebrown;
}

.actFiltro {
    padding-left: 4px;
    padding-right: 4px; 
    margin-left: 2px; 
    margin-right: 2px; 
    border: 1px outset; 
    background-color: white;  
    cursor: pointer;  
}

.actFiltro:hover {
    border: 1px inset;
}

.elemFiltroGen {
    font-size: 12px;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    cursor: pointer;    
    border: 2px outset;
}

.elemFiltroGen:hover {
    border: 2px inset;
}

.filtroOff {
    background-color: white;
    color: black;
}

.filtroOn {
    background-color: midnightblue;
    color: white;
}

.contAccion div {
    margin: 3px;
}

.contAccionInfo {
    display: flex;
    align-items: baseline;
}

.accionId {
    float: left;
    clear: both;
    font-weight: bolder;
    font-size: 25px;
    padding: 5px;
    background-color:darkslategrey;
    color:white;
    border-radius: 5px;
}

.accionTitulo {
    font-weight: bold;
}

.accionObjetivo {
    color:dimgrey;
    font-weight: bold;
    font-size: 12px;
    font-style: italic;
}

.contTactica {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
    /*border: 1px dashed grey;*/
}

.contTacticaDesc {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    font-size: 15px;
}

.contTacticaDesc div {
    margin: 3px;
}

.tacticaId {
    flex: 0 0 13%;
    text-align: center;
    font-weight: bolder;
    font-size: 15px;
    padding: 6px;
    border-radius: 5px;
}

.hito {
    background-color: saddlebrown;
    color:white;
}

.funcion {
    background-color: royalblue;
    color:white;
}

.tacticaDesc {
    border-radius: 5px;
    padding: 3px;
    font-weight: bold;
}

.indPrioridad {
    font-weight: bold;
    font-size: 11px;
    border-radius: 5px;
    padding: 3px;
    margin-left: 2px;
    margin-right: 2px;
    color: white;
    background-color: firebrick;
    text-align: center;
}

.contTacticaDetalles {
    display: flex;
}

.contTacticaCaps {
    display: flex;
    flex: 0 0 25em;
    flex-direction: column;
    margin: 3px;
}

.contCapRol {
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.contRol {
    font-size: 12px;
    flex: 0 0 6em;
    text-align: center;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 3px;
    margin-right: 5px;
    border-radius: 5px;
}

.contCapsDetalles {
    font-size: 10px !important;
}

.contCapDetalle {
    margin-bottom: 7px;
    justify-content: center;
}

.nombreCapacidad {
    margin: 3px;
    font-weight: bolder;
}

.contTacticaIndMDVs {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 3px;
}

.contInd {
    display: flex;
    margin: 3px;
    padding: 5px;
    /*border: 1px dashed grey;*/
    border-radius: 5px;
    justify-content: space-between;
}

.contIndDesc {
    margin-right: 3px;
}

.contIndDesc div {
    margin-bottom: 3px;
}

.contIndMeta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 5em;
    text-align: center;
}

.contIndMeta div {
    text-align: center;
    background-color: royalblue;
    color: white;
    border-radius: 5px;
    padding: 2px;
}

.contMDV {
    display: flex;
    margin: 3px;
    padding: 5px;
    /*border: 1px dashed grey;*/
    border-radius: 5px;
    justify-content: space-between;
}

.contMDVDesc {
    margin-right: 5px;
}

.contMDVPlazo {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex: 0 0 5em;
}

.contMDVPlazo div {
    text-align: center;
    background-color: saddlebrown;
    color: white;
    border-radius: 5px;
    padding: 2px;
}

.contListaEstandares {
    margin-top: 5px;
    font-size: 10px;
    color: midnightblue;
}

.contListaEstandares ul {
    margin: 0;
    list-style-position: outside;
    padding-left: 10px;
}

.filtrCont {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
}

.filtrEtiqueta {
    width: 70px;
    margin-left: 5px;
    color: mediumblue;
}

.botonDescarga {
    font-size: 12px;
    text-align: center;
    background-color: midnightblue;
    color: white;
    font-weight: bold;
    padding: 3px;
    margin-right: 5px;
    border-radius: 5px;
}

.botonDescarga:link {
    text-decoration: none;
}

.botonDescarga:visited {
    text-decoration: none;
}

.botonDescarga:hover {
    text-decoration: underline;
}

@media print {
    .headerFiltros {
        display: none;
    }

    .contTactica {
        break-inside: avoid;
    }

    .contAccion {
        break-inside: avoid;
    }
}